import { useEffect, useRef } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import {
  Color,
  Mesh,
  OrthographicCamera,
  Scene,
  WebGLRenderTarget,
  RGBAFormat,
  Vector2,
  Vector3,
  LinearFilter,
  PerspectiveCamera,
  AdditiveBlending,
  InstancedBufferGeometry,
  InstancedBufferAttribute,
  ShaderLib,
  ShaderMaterial,
  PlaneBufferGeometry,
  ClampToEdgeWrapping,
  NearestFilter,
  SphereBufferGeometry,
  MeshBasicMaterial
} from 'three'
import { isMobile } from 'react-device-detect'

import { useTexture } from '@react-three/drei'

import { config } from '../config'
import TextureHelper from '../helpers/TextureHelper'
import { useFlowMapStore } from '../state/flowMap'

// shaders
import fragmentShader from '../shaders/particles.frag'
import vertexShader from '../shaders/particles.vert'
import PassThroughVert from '../shaders/passThrough.vert'
import PositionFrag from '../shaders/position.frag'
import PassThroughFrag from '../shaders/passThrough.frag'

export default function ParticleRenderer () {
  const { gl, size, camera } = useThree()

  const flowMapRef = useRef(useFlowMapStore.getState().flowMap)

  // textures
  const [
    globeTexture
  ] =
    useTexture([
      'images/globeBW.jpg'
    ], (textures) => {
      textures.forEach((texture) => {
      })
    })

  // refs
  const frame = useRef(0)
  const textureHelper = useRef(null)
  const particleMaterial = useRef(null)
  const particleMesh = useRef(null)
  const positionMaterial = useRef(null)
  const quadCamera = useRef(null)
  const passThroughScene = useRef(null)
  const passThroughMaterial = useRef(null)
  const positionRenderTarget1 = useRef(null)
  const positionRenderTarget2 = useRef(null)
  const outputPositionRenderTarget = useRef(null)
  const positionScene = useRef(null)
  const positionMesh = useRef(null)
  const globeScene = useRef(null)
  const RTGlobe = useRef(null)
  const RTGlobeWireframe = useRef(null)
  const RTParticles = useRef(null)
  const particleScene = useRef(null)
  const particleCamera = useRef(null)

  useEffect(
    () =>
      useFlowMapStore.subscribe(
        (state) => (flowMapRef.current = state.flowMap)
      ),
    []
  )

  // constructor
  useEffect(() => {
    /**
     * Particle Setup
     */
    const step = 4
    const numPoints = config.particleScene.width * size.height
    const particleCount = Math.round(numPoints / (step * step))

    textureHelper.current = new TextureHelper({
      config
    })
    textureHelper.current.setTextureSize(particleCount)

    particleMaterial.current = new ParticlesMaterial({
      transparent: true,
      blending: AdditiveBlending
    })
    particleMaterial.current.uniforms.uTextureSize = { value: new Vector2(config.particleScene.width, config.particleScene.height) }
    particleMaterial.current.uniforms.uAspect = { value: size.width / size.height }

    const geometry = new InstancedBufferGeometry()

    const refGeo = new PlaneBufferGeometry(1, 1)
    geometry.setAttribute('position', refGeo.attributes.position)
    geometry.setAttribute('uv', refGeo.attributes.uv)
    geometry.setIndex(refGeo.index)

    const offsets = new Float32Array(particleCount * 2)
    for (let i = 0; i < particleCount; i++) {
      offsets[i * 2 + 0] = (i % (config.particleScene.width / step)) * step
      offsets[i * 2 + 1] = Math.floor(i / (config.particleScene.width / step)) * step
    }

    geometry.setAttribute('offset', new InstancedBufferAttribute(offsets, 2, false))

    const positionArray = new Float32Array(particleCount * 3)

    for (let i = 0; i < particleCount; i++) {
      const textureLocation = textureHelper.current.getNodeTextureLocation(i)
      positionArray[i * 3 + 0] = textureLocation.x
      positionArray[i * 3 + 1] = textureLocation.y
    }

    const tPosition = new InstancedBufferAttribute(positionArray, 3)
    geometry.setAttribute('tPosition', tPosition)

    particleMesh.current = new Mesh(geometry, particleMaterial.current)
    particleMesh.current.position.z = 0.1

    positionMaterial.current = new ShaderMaterial({
      uniforms: {
        positionTexture: {
          type: 't',
          value: null
        },
        defaultPositionTexture: {
          type: 't',
          value: null
        },
        initialPositionTexture: {
          type: 't',
          value: null
        },
        uNoiseMix: {
          type: 'f',
          value: 1.0
        },
        uFrame: {
          type: 'f',
          value: 0.0
        },
        uMousePos: {
          type: 'v2',
          value: new Vector2(0, 0)
        },
        uPrevMousePos: {
          type: 'v2',
          value: new Vector2(0, 0)
        }
      },
      vertexShader: PassThroughVert,
      fragmentShader: PositionFrag
    })

    initCamera()
    initPassThrough()
    initRenderTargets()
    initPositions()

    /**
     * Globe Setup
     */
    initGlobeScene()
    initParticleScene()
  }, [])

  function initCamera () {
    quadCamera.current = new OrthographicCamera()
    quadCamera.current.position.z = 1
  }

  function initPassThrough () {
    passThroughScene.current = new Scene()
    passThroughMaterial.current = new ShaderMaterial({
      uniforms: {
        texture: {
          type: 't',
          value: null
        }
      },
      vertexShader: PassThroughVert,
      fragmentShader: PassThroughFrag
    })
    const mesh = new Mesh(new PlaneBufferGeometry(2, 2), passThroughMaterial.current)
    mesh.frustumCulled = false
    passThroughScene.current.add(mesh)
  }

  function initRenderTargets () {
    positionRenderTarget1.current = new WebGLRenderTarget(textureHelper.current.textureWidth, textureHelper.current.textureHeight, {
      wrapS: ClampToEdgeWrapping,
      wrapT: ClampToEdgeWrapping,
      minFilter: NearestFilter,
      magFilter: NearestFilter,
      format: RGBAFormat,
      type: config.floatType,
      depthWrite: false,
      depthBuffer: false,
      stencilBuffer: false
    })
    positionRenderTarget2.current = positionRenderTarget1.current.clone()
    outputPositionRenderTarget.current = positionRenderTarget1.current

    RTGlobe.current = new WebGLRenderTarget(
      size.width,
      size.height,
      {
        minFilter: LinearFilter,
        magFilter: LinearFilter,
        format: RGBAFormat,
        type: config.floatType,
        depthWrite: false,
        depthBuffer: false,
        stencilBuffer: false
      }
    )

    RTParticles.current = RTGlobe.current.clone()
    RTGlobeWireframe.current = RTGlobe.current.clone()
  }

  function initPositions () {
    const positionData = textureHelper.current.createPositionTexture()
    const defaultPositionTexture = positionData.positionTexture
    const initialPositionTexture = positionData.initialPositionTexture

    positionMaterial.current.uniforms.defaultPositionTexture.value = defaultPositionTexture
    particleMaterial.current.uniforms.defaultPositionTexture.value = defaultPositionTexture

    positionMaterial.current.uniforms.initialPositionTexture.value = initialPositionTexture
    particleMaterial.current.uniforms.initialPositionTexture.value = initialPositionTexture

    positionScene.current = new Scene()

    positionMesh.current = new Mesh(new PlaneBufferGeometry(2, 2), positionMaterial.current)
    positionMesh.current.frustumCulled = false
    positionScene.current.add(positionMesh.current)
  }

  function initGlobeScene () {
    globeScene.current = new Scene()
    // globeScene.current.background = config.scene.bgColor

    const geometry = new SphereBufferGeometry(config.scene.sphereRadius, 32, 32)
    const material = new MeshBasicMaterial({
      color: new Color(0xffffff),
      opacity: 1.0,
      map: globeTexture
    })
    const mesh = new Mesh(geometry, material)

    globeScene.current.add(mesh)

    // const pointLight = new PointLight(0xff0000, 5, 100)
    // pointLight.position.set(0, 0, 13)
    // globeScene.current.add(pointLight)
  }

  function initParticleScene () {
    particleScene.current = new Scene()
    // particleScene.current.background = new Color(0x000000)
    particleScene.current.add(particleMesh.current)

    particleCamera.current = new PerspectiveCamera(
      config.camera.fov,
      1.0,
      config.camera.near,
      config.camera.far
    )

    particleCamera.current.position.x = 0
    particleCamera.current.position.y = 0
    particleCamera.current.position.z = 0.97
    particleCamera.current.updateMatrixWorld()
  }

  function updatePositions () {
    let inputPositionRenderTarget = positionRenderTarget1.current
    outputPositionRenderTarget.current = positionRenderTarget2.current
    if (frame % 2 === 0) {
      inputPositionRenderTarget = positionRenderTarget2
      outputPositionRenderTarget.current = positionRenderTarget1.current
    }
    positionMaterial.current.uniforms.positionTexture.value = inputPositionRenderTarget.texture

    gl.setRenderTarget(outputPositionRenderTarget.current)
    gl.render(positionScene.current, quadCamera.current)

    particleMaterial.current.uniforms.positionTexture.value = outputPositionRenderTarget.current.texture
  }

  /**
   * Resize
   */
  useEffect(() => {
    const aspect = size.width / size.height

    particleMaterial.current.uniforms.uAspect = { value: aspect }
    particleMaterial.current.uniforms.uTextureSize = { value: new Vector2(size.width * config.particleScene.downScaleFactor, size.height * config.particleScene.downScaleFactor) }

    particleMesh.current.scale.set(1.0, aspect, 1.0)

    RTGlobe.current.setSize(size.width, size.height)
    RTParticles.current.setSize(size.width, size.height)
  }, [size])

  useFrame((state, delta) => {
    frame.current++

    if (config.scene.autoRotate) {
      globeScene.current.rotation.y += delta * config.scene.autoRotateSpeed
    }

    // standard scene
    gl.setRenderTarget(RTGlobe.current)
    gl.render(globeScene.current, camera)

    // particles scene
    particleMesh.current.material.uniforms.uTexture.value = RTGlobe.current.texture

    positionMaterial.current.uniforms.uFrame.value = frame.current
    // positionMaterial.current.uniforms.uMousePos.value = MouseClass.getInstance().normalizedMousePos
    // positionMaterial.uniforms.uPrevMousePos.value = MouseClass.getInstance().prevNormalizedMousePos

    particleMaterial.current.uniforms.uTime.value += delta
    // particleMaterial.current.uniforms.uMousePos.value = MouseClass.getInstance().normalizedMousePos
    // particleMaterial.current.uniforms.uPrevMousePos.value = MouseClass.getInstance().prevNormalizedMousePos
    particleMaterial.current.uniforms.uMousePosTexture.value = flowMapRef.current
    particleMaterial.current.uniforms.uCamPos.value = camera.position
    particleMaterial.current.uniforms.uIsMobile.value = isMobile

    updatePositions()

    gl.setRenderTarget(null)

    gl.autoClear = false
    gl.clearDepth()

    gl.render(particleScene.current, particleCamera.current)

    // if (isMobile) {
    //   if (Math.abs(TouchClass.getInstance().touchDelta.x) + Math.abs(TouchClass.getInstance().touchDelta.y) > 1.0) {
    //     mouseMoved = 1.0
    //   }
    // } else {
    //   if (Math.abs(MouseClass.getInstance().mouseDelta.x) + Math.abs(MouseClass.getInstance().mouseDelta.y) > 1.0) {
    //     mouseMoved = 1.0
    //   }
    // }

    // if (mouseMoved > 0) {
    //   mouseMoved -= args.dt * 0.7
    // }
    // if (mouseMoved < 0) {
    //   mouseMoved = 0
    // }

    // material.uniforms.uNoiseMix.value = mouseMoved
    // positionMaterial.uniforms.uNoiseMix.value = mouseMoved
  }, 10)

  return (
    <>
    </>
  )
}

class ParticlesMaterial extends ShaderMaterial {
  constructor (config) {
    super(config)

    this.type = 'ShaderMaterial'

    this.uniforms = ShaderLib.standard.uniforms

    this.uniforms.uTexture = {
      type: 't',
      value: null
    }

    this.uniforms.uMousePosTexture = {
      type: 't',
      value: null
    }

    this.uniforms.uTime = {
      type: 'f',
      value: 0.0
    }

    this.uniforms.positionTexture = {
      type: 't',
      value: null
    }

    this.uniforms.initialPositionTexture = {
      type: 't',
      value: null
    }

    this.uniforms.defaultPositionTexture = {
      type: 't',
      value: null
    }

    this.uniforms.uMousePos = {
      type: 'v2',
      value: new Vector2(0, 0)
    }

    this.uniforms.uPrevMousePos = {
      type: 'v2',
      value: new Vector2(0, 0)
    }

    this.uniforms.uNoiseMix = {
      type: 'f',
      value: 0.0
    }

    this.uniforms.uAspect = {
      type: 'f',
      value: 1.0
    }

    this.uniforms.uCamPos = {
      type: 'v3',
      value: new Vector3(0, 0, 0)
    }

    this.uniforms.uIsMobile = {
      type: 'f',
      value: 0.0
    }

    this.vertexShader = vertexShader
    this.fragmentShader = fragmentShader
    this.lights = true
    this.transparent = true
  }
}
