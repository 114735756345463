import create from 'zustand'

import { Vector2 } from 'three'

const useModalStore = create((set) => ({
  modalOpen: false,
  setModalOpen: (value) => set(() => ({ modalOpen: value })),
  eventDetails: '',
  setEventDetails: (value) => set(() => ({ eventDetails: value })),
  position: new Vector2(),
  setPosition: (value) => set(() => ({ position: value })),
  zoom: 14,
  setZoom: (value) => set(() => ({ zoom: value }))
}))

export { useModalStore }
