export default function Footer () {
  return (
    <>
      <footer>
        <div className='footer-inner'>
          <div className='footer-links'>
            <a target='_blank' href='https://static.iohk.io/terms/iog-privacy-policy.pdf' rel='noreferrer'>
              Privacy policy
            </a>
            <a
              target='_blank'
              href='https://static.iohk.io/terms/iohktermsandconditions.pdf'
              rel='noreferrer'
            >
              Terms and conditions
            </a>
            <a target='_blank' href='https://www.essentialcardano.io/additional-disclosures' rel='noreferrer'>
              Additional Disclosures
            </a>
          </div>
          <div className='copyright'>
            &copy; {new Date().getFullYear()} Input Output Global
          </div>
        </div>
      </footer>
    </>
  )
}
