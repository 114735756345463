import { useRef } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import Globe from './Globe'

export default function Main () {
  const scene = useRef()
  const { camera } = useThree()
  useFrame(({ gl }) => {
    gl.autoClear = true
    gl.render(scene.current, camera)
  }, 0)

  return (
    <scene ref={scene}>
      <Globe />
    </scene>
  )
}
