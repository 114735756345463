
import { useModalStore } from '../state/ModalStore'

import { useEffect, useRef } from 'react'

export default function Modal () {
  const modalOpen = useModalStore((state) => state.modalOpen)
  const setModalOpen = useModalStore((state) => state.setModalOpen)
  const setZoom = useModalStore((state) => state.setZoom)
  const setEventDetails = useModalStore((state) => state.setEventDetails)
  const eventDetails = useModalStore((state) => state.eventDetails)
  const position = useRef(useModalStore.getState().position)

  const modalHTMLRef = useRef()

  useEffect(
    () =>
      useModalStore.subscribe(
        (state) => (position.current = state.position)
      ),
    []
  )

  useEffect(() => {
    animate()
  }, [])

  useEffect(() => {
    let className = 'modal'

    if (modalOpen) {
      className += ' open'
    }

    if (modalHTMLRef.current) {
      modalHTMLRef.current.className = className
    }
  }, [modalOpen])

  function animate () {
    if (modalHTMLRef.current) {
      modalHTMLRef.current.style.left = `${position.current.x}px`
      modalHTMLRef.current.style.top = `${position.current.y}px`
    }
    window.requestAnimationFrame(animate)
  }

  return (
    <>
      {eventDetails && (
        <div ref={modalHTMLRef} style={{ background: `#fff url(images/locations/${eventDetails.image}) no-repeat` }}>
          <button
            className='close' onClick={() => {
              setModalOpen(false)
              setEventDetails(null)
              setZoom(14)
            }}
          >
            <svg
              stroke='currentColor'
              fill='currentColor'
              strokeWidth='0' viewBox='0 0 512 512'
              height='2em'
              width='2em' xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z' />
            </svg>
          </button>
          <h1>{eventDetails.company}</h1>
          <h2>{eventDetails.location}</h2>
          <h3>{eventDetails.date !== '' ? eventDetails.date : 'TBC'}</h3>
          <p><b>Main Contact:</b> {eventDetails.primary_name}</p>
          {eventDetails.support_name && (
            <p><b>Contact(s):</b> {eventDetails.support_name}</p>
          )}
          {/* {(eventDetails.email && eventDetails.email.length > 0) && (
          <>
            <p className='listTitle'>Email:</p>
            <ul className='emailList'>
              {eventDetails.email.map((email, i) => (
                <li key={i}>{email}</li>
              ))}
            </ul>
          </>
        )} */}
          {(eventDetails.discord && eventDetails.discord.length > 0) && (
            <>
              <p className='listTitle'>Discord:</p>
              <ul className='emailList'>
                {eventDetails.discord.map((discord, i) => (
                  <li key={i}>{discord}</li>
                ))}
              </ul>
            </>
          )}
        </div>
      )}
    </>
  )
}
