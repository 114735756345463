import { isMobile, isIOS } from 'react-device-detect'
import { FloatType, HalfFloatType, Color } from 'three'

const queryString = window.location.search
const urlParams = new URLSearchParams(queryString)
const flythrough = urlParams.has('flythrough')
const langParam = urlParams.get('lang')

const supportedLang = ['EN', 'JP']

let lang = 'EN'
if (supportedLang.indexOf(langParam) !== -1) {
  lang = langParam
}

const config = {
  lang,
  floatType: isIOS && isMobile ? HalfFloatType : FloatType,
  camera: {
    fov: 60,
    initPos: { x: 0, y: 5, z: isMobile ? 18 : 13 },
    near: 0.1,
    far: 20
  },
  controls: {
    autoRotate: true,
    minDistance: 4,
    enabled: true,
    enablePan: false
  },
  defaultCursor: 'grab',
  showHTML: true,
  showHomePageHTML: false,
  particleScene: {
    width: 3300,
    height: 1500,
    downScaleFactor: 0.5
  },
  scene: {
    autoRotate: false,
    autoRotateSpeed: 0.05,
    bgColor: new Color(0x082568),
    globeColor: new Color(0x02194e),
    sphereRadius: 2,
    globeRadius: 2.1,
    markersClickable: true,
    flyThrough: false,
    lensFlare: false
  },
  paths: {
    opacity: 0.1,
    lineCount: 100,
    curveMinAltitude: 0,
    curveMaxAltitude: 1.5,
    curveSegments: 64
  }
}

if (flythrough === true) {
  config.controls.autoRotate = false
  config.controls.enabled = false
  config.defaultCursor = 'default'
  config.showHTML = false
  config.scene.autoRotate = true
  config.scene.markersClickable = false
  config.scene.flyThrough = true
  config.scene.lensFlare = true
  config.paths.opacity = 0.2
  config.paths.lineCount = 200
  config.showHomePageHTML = true
}

config.particleScene.width *= config.particleScene.downScaleFactor
config.particleScene.height *= config.particleScene.downScaleFactor

export { config }
