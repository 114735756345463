import { useGLTF, Merged, useTexture } from '@react-three/drei'

import { config } from '../config'
import { useEffect, useRef, useState } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import { Object3D, Color, Vector3, Vector2 } from 'three'
import * as TWEEN from '@tweenjs/tween.js'

import locations from '../data/workshops.json'
import { latLongToCartesian } from '../helpers/math'

import { useModalStore } from '../state/ModalStore'
import { isMobile } from 'react-device-detect'

export default function Markers ({ controlsRef }) {
  const imageArray = []
  locations.forEach(event => {
    imageArray.push('images/locations/' + event.image)
  })
  useTexture(imageArray)

  // camera animation
  const { camera, size } = useThree()
  const camTween = useRef()
  const scene = useRef()
  const selectionRef = useRef(new Object3D())
  const selectionRefPos = useRef(new Vector3())
  const selectedNodePosScreen = useRef(new Vector2())

  const [hovered, setHovered] = useState(false)
  useEffect(() => {
    document.body.style.cursor = hovered ? 'pointer' : config.defaultCursor
  }, [hovered])

  // tooltip
  const setModalOpen = useModalStore((state) => state.setModalOpen)
  const eventDetails = useModalStore((state) => state.eventDetails)
  const setEventDetails = useModalStore((state) => state.setEventDetails)
  const setPosition = useModalStore((state) => state.setPosition)
  const setZoom = useModalStore((state) => state.setZoom)

  // morker positions
  const dummyObject = new Object3D()
  locations.forEach((data, i) => {
    const pos = latLongToCartesian(data.lat, data.long, config.scene.sphereRadius * 1.06)

    dummyObject.rotation.set(0, 0, 0)
    dummyObject.position.set(pos.x, pos.y, pos.z)
    dummyObject.lookAt(0, 0, 0)

    data.position = pos
    data.rotation = dummyObject.rotation.clone()
  })

  useEffect(() => {
    controlsRef.current.addEventListener('end', (e) => {
      setZoom(e.target.getDistance())
    })
  }, [])

  // marker models
  const { nodes } = useGLTF('models/pin2.glb')
  useEffect(() => {
    nodes.Sphere.material.transparent = true
    nodes.Sphere.material.roughness = 0.5
    nodes.Sphere.material.metalness = 0.4
    nodes.Sphere.material.color = new Color(0xaa4960)
    nodes.Sphere.material.emissive = new Color(0xaa4960)
    nodes.Sphere.material.needsUpdate = true

    nodes.Cone.material.transparent = true
    nodes.Cone.material.color = new Color(0xaa4960)
    nodes.Cone.material.needsUpdate = true
  }, [nodes])

  useEffect(() => {
    if (eventDetails === null) {
      resetCamera()
    }
    if (eventDetails) {
      highlight(eventDetails)
    }
  }, [eventDetails])

  useFrame(({ gl }) => {
    TWEEN.update()

    setNodePosInScreenSpace()

    gl.autoClear = false
    gl.clearDepth()
    gl.render(scene.current, camera)
  }, 100)

  function getArcFromCoords (camPos, endPos, steps) {
    // get normal of both points
    const cb = new Vector3()
    const ab = new Vector3()
    const normal = new Vector3()
    cb.subVectors(new Vector3(), endPos)
    ab.subVectors(camPos, endPos)
    cb.cross(ab)
    normal.copy(cb).normalize()

    const angle = camPos.angleTo(endPos) // get the angle between vectors
    const angleDelta = angle / (steps)

    const delta = 1 / steps
    const points = []
    for (let i = 0; i <= steps; i++) {
      const position = camPos.clone().applyAxisAngle(normal, angleDelta * i)
      position.lerp(endPos.clone().multiplyScalar(2), delta * i)
      points.push(position)
    }

    return points
  }

  function setNodePosInScreenSpace () {
    selectionRefPos.current.setFromMatrixPosition(selectionRef.current.matrixWorld)
    selectionRefPos.current.project(camera)

    const widthHalf = size.width / 2
    const heightHalf = size.height / 2

    selectedNodePosScreen.current.x = selectionRefPos.current.x * widthHalf + widthHalf
    selectedNodePosScreen.current.y = (1 - selectionRefPos.current.y * heightHalf) + heightHalf

    setPosition(selectedNodePosScreen.current)
  }

  function setSelectionRef (pos) {
    selectionRef.current.position.set(pos.x, pos.y, pos.z)
    selectionRef.current.updateMatrixWorld()
  }

  function resetCamera () {
    if (camTween.current) {
      camTween.current.stop()
    }

    const forwardVec = new Vector3()
    camera.getWorldDirection(forwardVec)
    forwardVec.multiplyScalar(9.5)
    const newPos = camera.position.clone()
    newPos.sub(forwardVec)

    // disable orbit controls when animating camera
    controlsRef.current.autoRotate = false
    controlsRef.current.enabled = false

    camTween.current = new TWEEN.Tween({ position: camera.position })
      .to({ position: newPos }, 1000)
      .onComplete(() => {
        controlsRef.current.enabled = true // re-enable orbit controls
        setModalOpen(true)
      })
      .easing(TWEEN.Easing.Quadratic.InOut)
      .start()
  }

  function highlight (props, delay = 1000) {
    if (camTween.current) {
      camTween.current.stop()
    }

    setSelectionRef(props.position.clone())

    const offsetPos = latLongToCartesian(props.lat - 3, props.long + 4.5, config.scene.sphereRadius * 1.07)

    const steps = 25
    const points = getArcFromCoords(camera.position, offsetPos, steps)

    // disable orbit controls when animating camera
    controlsRef.current.autoRotate = false
    controlsRef.current.enabled = false

    camTween.current = new TWEEN.Tween({ step: 0 })
      .to({ step: steps }, delay)
      .onUpdate(function (e) {
        // lerp between points on arc
        const pos1 = points[Math.floor(e.step)]
        const pos2 = points[Math.floor(e.step + 1)]
        if (typeof pos2 !== 'undefined') {
          const pos = pos1.clone().lerp(pos2, e.step % 1)
          camera.position.set(pos.x, pos.y, pos.z)
          camera.lookAt(0, 0, 0)
        }
      })
      .onComplete(() => {
        controlsRef.current.enabled = true // re-enable orbit controls
        setModalOpen(true)
      })
      .easing(TWEEN.Easing.Quadratic.InOut)
      .start()
  }

  const markerSphereScale = isMobile ? 4 : 1.8

  return (
    <scene ref={scene}>
      <pointLight
        position={[0, 4, 4]}
        intensity={0.6}
      />
      <ambientLight intensity={0.5} />
      <mesh renderOrder={-1}>
        <icosahedronBufferGeometry args={[config.scene.sphereRadius, 16, 16]} />
        <meshBasicMaterial
          opacity={0.0}
          transparent
        />
      </mesh>
      <group renderOrder={10}>
        <Merged meshes={nodes}>
          {({ Cone, Sphere }) => (
            <group>
              {locations.map((props, i) => (
                <group key={i} {...props}>
                  <group rotation={[Math.PI / 2, 0, 0]} scale={[0.012, 0.012, 0.012]}>
                    <Cone scale={[2, 4, 2]} />
                    <Sphere
                      scale={[markerSphereScale, markerSphereScale, markerSphereScale]}
                      position={[0, -9.9, 0]}
                      onPointerDown={() => {
                        if (config.scene.markersClickable === false) {
                          return
                        }

                        setZoom(4)
                        setModalOpen(false)
                        if (eventDetails && props.uid === eventDetails.uid) {
                          highlight(props, 100)
                        }
                        setEventDetails(props)
                      }}
                      onPointerOver={(e) => {
                        if (config.scene.markersClickable === false) {
                          return
                        }

                        setHovered(true)
                        e.eventObject.scale.set(2.4, 2.4, 2.4)
                      }}
                      onPointerOut={(e) => {
                        if (config.scene.markersClickable === false) {
                          return
                        }

                        setHovered(false)
                        e.eventObject.scale.set(markerSphereScale, markerSphereScale, markerSphereScale)
                      }}
                    />
                  </group>
                </group>
              ))}
            </group>
          )}
        </Merged>
      </group>
    </scene>
  )
}
